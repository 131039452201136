import React from 'react';
import {Person} from '../models';
import styled from 'styled-components';
import {sizes} from '../../../styles/mediaQueries';
import {useConfiguration} from '../../../configuration/useConfiguration';

interface ComponentProps {
  person: Person;
}

const AboutUsPerson: React.FC<ComponentProps> = ({ person }) => {
  const { cdnUrl } = useConfiguration();

  return (
    <SC.AboutUsPerson>
      <div className={'person'}>
        <div className={'circle-wrapper'}>
          <img src={cdnUrl + person.image} alt={person.name} loading={'lazy'} />
          <div className={'circle-wrapper__rotary'}>
            <div className={'circle-wrapper__rotary__block'} />
          </div>
        </div>
        <div className={'content'}>
          <div className={'about-content about-person about-content-show'}>
            <h3>{person.name}</h3>
            <h4>{person.position}</h4>
            <p>{person.aboutPerson}</p>
          </div>
          <div className={'about-content about-contact'}>
            <h3>{person.name}</h3>
            <h4>{person.position}</h4>
            <ul>
              <li>
                <a href={'#'}>{person.email}</a>
              </li>
              <li>{person.phone}</li>
            </ul>
          </div>
        </div>
      </div>
    </SC.AboutUsPerson>
  );
};

export default AboutUsPerson;

export const SC = {
  AboutUsPerson: styled.div`
    :hover {
      .circle-wrapper {
        &__rotary {
          transform: rotate(-90deg);
        }
      }
    }

    .person {
      position: unset;
      height: 60rem;

      @media (max-width: ${sizes.mDevice}) {
        height: 52rem;
      }

      &:hover .content {
        .about-content {
          opacity: 0;
          @media (max-width: ${sizes.mDevice}) {
            display: none;
          }
        }

        .about-person {
          opacity: 0;
          @media (max-width: ${sizes.mDevice}) {
            display: none;
          }
        }

        .about-contact {
          opacity: 1;
          @media (max-width: ${sizes.mDevice}) {
            display: block;
          }
        }
      }

      .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        position: relative;
        top: -20.3rem;

        @media (max-width: ${sizes.mDevice}) {
          position: unset;
          top: 1rem;
          grid-template-columns: 1fr;
          max-height: 30rem;
          overflow: hidden;
        }

        .about-content {
          opacity: 0;
          padding: 1.5rem;
          transition: opacity 0.3s ease-in;

          &-show {
            opacity: 1;
          }

          h3 {
            font: normal normal 950 18px/26px 'Area Normal Black';
          }

          h4 {
            font: normal normal 500 13px/25px 'Area Normal Medium';
          }

          p {
            font: normal normal 14px/20px 'Area Normal Medium';
          }
        }

        .about-person {
          text-align: right;

          @media (max-width: ${sizes.mDevice}) {
            text-align: left;
          }
        }

        .about-contact {
          @media (max-width: ${sizes.mDevice}) {
            text-align: left;
          }

          li {
            font: normal 950 14px/22px 'Area Normal Extrablack';
          }
        }
      }
    }

    .circle-wrapper {
      display: block;
      width: 40rem;
      height: 40rem;
      top: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      transition: transform 0.3s ease-in;
      position: relative;
      overflow: hidden;

      @media (max-width: ${sizes.mDevice}) {
        width: 23rem;
        height: 23rem;
        margin-right: initial;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      &__rotary {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: transform 0.3s ease-in;

        &__block {
          width: 20rem;
          height: 20rem;
          position: absolute;
          top: 50%;
          right: 50%;
          background-color: #fff;
        }
      }
    }
  `,
};
