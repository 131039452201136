import styled, { css } from 'styled-components';
import { sizes } from '../../../../../styles/mediaQueries';

export const SC = {
  TextContainerImageComponent: styled.div<{ hasImage: boolean }>(
    ({ theme, hasImage }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .content-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 10rem;
        align-items: center;

        @media (max-width: ${sizes.mDevice}) {
          display: flex;
          flex-direction: column;
        }

        .side {
          &.image {
            position: relative;
            text-align: end;
            display: flex;
            justify-content: center;
            @media (max-width: ${sizes.mDevice}) {
              display: none;
            }

            img {
              border-radius: 50px;
              width: 50vw;
              height: 65vh;
              object-fit: cover;
              @media (max-width: ${sizes.mDevice}) {
                width: 100%;
              }
            }
          }

          &.content {
            .title {
              font: 950 25px / 40px 'Area Extended ExtraBlack';
              padding-bottom: 2rem;
              margin: 0;
            }

            .description {
              font: normal 16px/24px 'Area Normal Medium';

              h3 {
                font: 950 25px / 40px 'Area Extended ExtraBlack';
                position: relative;
              }

              h4 {
                font: normal normal 950 20px 'Area Extended ExtraBlack';
                position: relative;
                margin-bottom: 2rem;
              }

              p {
                font: normal 16px/24px 'Area Normal Medium';
                padding-bottom: 2rem;
              }

              strong {
                font: 950 15px / 24px 'Area Extended ExtraBlack';
              }

              ul {
                font: normal 16px/24px 'Area Normal Medium';
                margin-bottom: 2rem;

                li {
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                  list-style: disc;
                  margin-left: 4rem;
                }
              }
            }

            .action {
              padding-top: 5rem;
              display: flex;
              gap: 2rem;

              .second {
                border: 1px solid black;
                background-color: transparent;

                :hover {
                  background-color: #f6e600;
                  border-color: #f6e600;
                }
              }

              a {
                transition: background-color 0.1s ease-in-out;
                border: 1px solid #f6e600;
                border-radius: 4px;
                padding: 1.5rem 3rem;
                font: normal 950 15px 'Area Normal Black';
                width: fit-content;
                cursor: pointer;
                user-select: none;
                background-color: #f6e600;

                :hover {
                  background-color: #e8d900;
                  border-color: #e8d900;
                }
              }
            }
          }
        }
      }
    `
  ),
};
