import React from 'react';
import styled, {css} from 'styled-components';
import {sizes} from '../../../../styles/mediaQueries';

interface ComponentProps {
  content: any;
}

const TextPlainComponent: React.FC<ComponentProps> = ({ content }) => {
  return (
    <SC.TextPlain>
      <div className={'text'} dangerouslySetInnerHTML={{ __html: content as string }} />
    </SC.TextPlain>
  );
};

export default TextPlainComponent;

export const SC = {
  TextPlain: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .text {
        font: normal 17px/24px 'Area Normal Medium';
        text-align: left;

        p {
          margin-bottom: 2rem;
        }

        h3 {
          font: normal normal 950 18px/24px 'Area Extended ExtraBlack';
        }

        strong {
          font-family: 'Area Extended ExtraBlack';
        }

        ul {
          margin-bottom: 5rem;

          li {
            list-style: outside;
            margin-left: 2rem;
            margin-bottom: 1rem;
          }
        }

        img {
          object-fit: cover;
          overflow: hidden;
          border-radius: 20px;
          max-height: 50rem;
          margin: 4rem 0;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          aspect-ratio: 1/1;
        }
      }
    `
  ),
};
