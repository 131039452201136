import React from 'react';
import styled, {css} from 'styled-components';
import {sizes} from '../../../styles/mediaQueries';
import AboutUsPerson from './aboutUsPerson';
import {PageBlockContentType} from '../../app/models/enums';
import {getRows, getValueFromList} from '../../page/helper';

interface ComponentProps {
  title: string;
  data: Array<{
    id: string;
    type: PageBlockContentType;
    value: string;
    additionalInfo: string;
  }>;
}

const AboutUsBlock: React.FC<ComponentProps> = ({ title, data }) => {
  return (
    <SC.AboutUs id={'about-us-block'}>
      <div className={'block-headline'}>
        <div>{title}</div>
      </div>
      <div className={'list'}>
        {[...getRows(data)].map(([key, value]) => {
          return (
            <AboutUsPerson
              person={{
                image: getValueFromList(value, PageBlockContentType.PEOPLE_BLOCK_IMAGE) as string,
                name: getValueFromList(value, PageBlockContentType.PEOPLE_BLOCK_NAME) as string,
                position: getValueFromList(value, PageBlockContentType.PEOPLE_BLOCK_POSITION) as string,
                aboutPerson: getValueFromList(value, PageBlockContentType.PEOPLE_BLOCK_DESCRIPTION) as string,
                email: getValueFromList(value, PageBlockContentType.PEOPLE_BLOCK_EMAIL) as string,
                phone: getValueFromList(value, PageBlockContentType.PEOPLE_BLOCK_PHONE) as string,
              }}
            />
          );
        })}
      </div>
    </SC.AboutUs>
  );
};

export default AboutUsBlock;

export const SC = {
  AboutUs: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .block {
        &-headline {
          padding-bottom: 120px;
          @media (max-width: ${sizes.mDevice}) {
            padding-bottom: 40px;
          }
        }
      }

      .list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;

        @media (max-width: ${sizes.mDevice}) {
          grid-template-columns: 1fr;
        }
      }
    `
  ),
};
