import React from 'react';
import { getValueFromList } from '../../../page/helper';
import { PageBlockContentType } from '../../../app/models/enums';
import styled, { css } from 'styled-components';
import { sizes } from '../../../../styles/mediaQueries';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { isServer } from '../../../../utils';

interface ComponentProps {
  data: any;
}

const MainPromoComponent: React.FC<ComponentProps> = ({ data }) => {
  return (
    <SC.MainPromo>
      <div className={'cta'}>
        <div
          dangerouslySetInnerHTML={{
            __html: getValueFromList(data, PageBlockContentType.MAIN_PROMO_BLOCK_LEFT_SIDE) as string,
          }}
        />
        <div className={'btn-arrow'}>
          Chci nezávaznou konzultaci
          <div className={'arrow'} />
        </div>
      </div>
      <div
        className={'content'}
        dangerouslySetInnerHTML={{
          __html: getValueFromList(data, PageBlockContentType.MAIN_PROMO_BLOCK_RIGHT_SIDE) as string,
        }}
      ></div>
    </SC.MainPromo>
  );
};

export default MainPromoComponent;

export const SC = {
  MainPromo: styled.div(
    ({ theme }) => css`
      font: normal normal 950 25px/60px 'Area Extended Extrablack';
      letter-spacing: 0;
      color: #000;
      text-align: left;
      grid-template-columns: 2fr 3fr;
      padding-bottom: 120px;

      display: none;

      @media (max-width: ${sizes.mDevice}) {
        display: block;
        font: normal 950 32px/36px 'Area Extended Extrablack';
        text-align: left;
        padding-bottom: 40px;
      }

      .cta {
        @media (max-width: ${sizes.mDevice}) {
          padding-bottom: 5rem;
        }

        h2 {
          text-align: left;
          font: normal normal 950 30px/40px 'Area Extended Extrablack';
          letter-spacing: 0;
          color: #000000;
          opacity: 1;
          margin: 0;
          @media (max-width: ${sizes.mDevice}) {
            font: normal normal 950 20px/40px 'Area Extended Extrablack';
          }
        }

        .btn-arrow {
          margin-top: 4rem;
        }
      }

      .content {
        p {
          margin-bottom: 4rem;
          @media (max-width: ${sizes.mDevice}) {
            font: normal normal 950 25px/40px 'Area Extended Extrablack';
          }
        }

        a {
          color: rgb(0, 0, 0);
          cursor: pointer;
          position: relative;
          background: rgb(248, 232, 0);
          line-height: 46px;
          padding: 6px 8px;
          text-decoration: underline;

          @media (max-width: ${sizes.mDevice}) {
            padding: 6px 8px;
            line-height: 45px;
          }

          .hint {
            opacity: 0;
            text-decoration: none !important;
            background: #f8e800 0 0 no-repeat padding-box;
            border: 1px solid #000000;
            border-radius: 14px;
            text-align: center;
            font: normal normal 950 15px/25px 'Area Normal Medium';
            letter-spacing: 0;
            color: #000000;
            position: absolute;
            top: 0;
            padding: 0.2rem 2rem;
            margin-top: -3rem;
            transition: margin 0.3s ease-out, opacity 0.3s ease-out;

            &:after {
              content: '';
              display: block;
              width: 15px;
              height: 15px;
              position: absolute;
              top: 22px;
              background: #f8e800 0 0 no-repeat padding-box;
              margin-right: auto;
              margin-left: auto;
              right: 0;
              left: 0;
              border: 1px solid #000;
              border-top: 0;
              border-right: 0;
              transform: rotate(-45deg);
            }
          }

          &:hover .hint {
            opacity: 1;
            text-decoration: none;
            margin-top: -3.8rem;
          }
        }
      }

      .link {
        display: inline-block;
        text-decoration: underline;
        color: #000;
        cursor: pointer;
        position: relative;
        background: #f8e800;
        line-height: 40px;
        padding: 0 6px;

        .hint {
          opacity: 0;
          text-decoration: none !important;
          background: #f8e800 0 0 no-repeat padding-box;
          border: 1px solid #000000;
          border-radius: 14px;
          text-align: center;
          font: normal normal 950 15px/25px 'Area Normal Medium';
          letter-spacing: 0;
          color: #000000;
          position: absolute;
          top: 0;
          padding: 0.2rem 2rem;
          margin-top: -3rem;
          transition: margin 0.3s ease-out, opacity 0.3s ease-out;

          &:after {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 22px;
            background: #f8e800 0 0 no-repeat padding-box;
            margin-right: auto;
            margin-left: auto;
            right: 0;
            left: 0;
            border: 1px solid #000;
            border-top: 0;
            border-right: 0;
            transform: rotate(-45deg);
          }
        }

        &:hover .hint {
          opacity: 1;
          text-decoration: none;
          margin-top: -3.8rem;
        }
      }
    `
  ),
};
