import React from 'react';
import {SC} from './styles/servicesComponent';
import RouteLink from '../../../app/components/RouteLink';
import {useRouting} from '../../../../routing/utils/useRouting';
import {ServicesRowChildren} from '../../../service/models';
import {getRows, getValueFromList} from '../../../page/helper';
import {PageBlockContentType} from '../../../app/models/enums';

interface ComponentProps {
  title: string;
  data: ServicesRowChildren[];
  content: string;
  cta?: string | null;
  direction?: string | null;
}

const RelatedServicesComponent: React.FC<ComponentProps> = ({ title, data, content }) => {
  const { routes } = useRouting();

  return (
    <SC.Services hasImage={false}>
      <div id={'services'}>
        <div className={'container yellow-container related'}>
          <div className={'yellow-container__title'}>{title}</div>
          <div>
            <p>{content}</p>
          </div>
          <div className={'yellow-container__grid'}>
            <div className={'yellow-container__list'}>
              {[...getRows(data)].map(([key, value]) => {
                return (
                  <RouteLink
                    className={'yellow-container__list__item'}
                    to={routes.service.url({
                      serviceSlug: getValueFromList(value, PageBlockContentType.RELATED_SERVICE_BLOCK_SERVICE_URL),
                    })}
                  >
                    {getValueFromList(value, PageBlockContentType.RELATED_SERVICE_BLOCK_SERVICE_TITLE)}
                  </RouteLink>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </SC.Services>
  );
};

export default RelatedServicesComponent;
