import React from 'react';
import styled, { css } from 'styled-components';
import { sizes } from '../../../styles/mediaQueries';
import StudyBlock from './studyBlock';
import { ProjectsRow } from '../../project/models';
import { Page } from '../../common/models';
import RouteLink from '../../app/components/RouteLink';
import { useRouting } from '../../../routing/utils/useRouting';

interface ComponentProps {
  projects?: Page<ProjectsRow> | undefined;
}

const StudiesBlock: React.FC<ComponentProps> = ({ projects }) => {
  const { router, routes } = useRouting();

  if (!projects) {
    return <></>;
  }

  return (
    <SC.Study>
      <div className={'block-headline'}>
        <div>Jak jsme pomohli našim klientům?</div>
      </div>
      <div className={'list'}>
        {projects.content.map((study) => (
          <StudyBlock study={study} />
        ))}
      </div>
      <div className={'block-cta'}>
        <RouteLink to={routes.projects.url()} className={'btn-cta-secondary'}>
          Všechny případové studie
        </RouteLink>
      </div>
    </SC.Study>
  );
};

export default StudiesBlock;

export const SC = {
  Study: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .block {
        &-headline {
          padding: 0;
          padding-bottom: 120px;
          @media (max-width: ${sizes.mDevice}) {
            padding-bottom: 40px;
          }
        }
      }

      .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 10rem;

        @media (max-width: ${sizes.mDevice}) {
          grid-template-columns: 1fr;
          padding-top: 3rem;
          padding-bottom: 4rem;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    `
  ),
};
