import React from 'react';
import styled, {css} from 'styled-components';
import {sizes} from '../../../../styles/mediaQueries';
import ServicesRow from '../../../homepage/components/servicesRow';

interface ComponentProps {
  title: string;
  content: any;
}

const MainServicesPromoComponent: React.FC<ComponentProps> = ({ title, content }) => {
  return (
    <SC.MainServicesPromo>
      <div id={'services'}>
        <div className={'block-headline'}>
          <div>Naše služby</div>
        </div>
        <div className={'content'}>
          <h3>{title}</h3>
          <div dangerouslySetInnerHTML={{ __html: content as string }} />
        </div>
        <ServicesRow />
      </div>
    </SC.MainServicesPromo>
  );
};

export default MainServicesPromoComponent;

export const SC = {
  MainServicesPromo: styled.div(
    ({ theme }) => css`
      #services::before {
        display: block;
        content: ' ';
        margin-top: -120px;
        height: 120px;
        visibility: hidden;
        pointer-events: none;
      }

      .block {
        &-headline {
          padding-bottom: 80px;
          @media (max-width: ${sizes.mDevice}) {
            padding-bottom: 50px;
          }
        }
      }

      .content {
        padding: 0 20rem;

        @media (max-width: ${sizes.mDevice}) {
          display: none;
        }

        h3 {
          font: normal 950 30px/36px 'Area Extended Extrablack';
        }

        p {
          text-align: left;
          font: normal normal 18px/30px 'Area Normal Medium';
          letter-spacing: 0;
          color: #000000;
          opacity: 1;
        }
      }
    `
  ),
};
