import React from 'react';

interface ComponentProps {
  title?: string;
  description?: string;
}

const UspRow: React.FC<ComponentProps> = ({
  title = 'Quisque sit amet quam ut justo.',
  description = 'Quisque sit amet quam ut justo vestibulum vehicula. Cras ac felis quam. Sed urna eros, sagittis a porttitor\n' +
    '        eu, feugiat id erat. Aenean et lectus feugiat, consequat leo a, aliquet magna.',
}) => {
  return (
    <div className={'bubble'}>
      {/*<div className={'content-bubble'}>*/}
      {/*  <div className={'content-bubble__bubble'} />*/}
      {/*</div>*/}
      <div className={'plus'}>
        <div className={'line'} />
        <div className={'line line-vertical'} />
      </div>
      <div className={'content-text'}>
        <div className={'title'}>{title}</div>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default UspRow;
