import React from 'react';
import styled, { css } from 'styled-components';
import { sizes } from '../../../styles/mediaQueries';
import { useRouting } from '../../../routing/utils/useRouting';
import RouteLink from '../../app/components/RouteLink';
import { BlogRow } from '../../blog/models';

interface ComponentProps {
  list?: BlogRow[];
}

const BlogsRow: React.FC<ComponentProps> = ({ list }) => {
  const { routes } = useRouting();

  if (!list) {
    return <></>;
  }

  return (
    <SC.BlogRow>
      <div className={'block-headline'}>
        <div>Články a aktuality</div>
      </div>
      <div className={'list'}>
        {list.map((row) => (
          <SC.BlogsRow>
            <RouteLink className={'blog'} to={routes.blog.url({ blogSlug: row.url })}>
              <div className={'blog__image'}>
                <img src={row.image} alt={row.title} loading={'lazy'} />
              </div>
              <div className={'blog__content'}>
                <RouteLink className={'badge'} to={routes.blog.url({ blogSlug: row.category.url })}>
                  {row.category.title}
                </RouteLink>
                <h3>{row.title}</h3>
                <p>{row.shortContent}</p>
                <div className={'btn-arrow'}>
                  Přečíst
                  <div className={'arrow'} />
                </div>
              </div>
            </RouteLink>
          </SC.BlogsRow>
        ))}
      </div>
      <div className={'block-cta'}>
        <RouteLink to={routes.projects.url()} className={'btn-cta-secondary'}>
          Prozkoumat všechny články
        </RouteLink>
      </div>
    </SC.BlogRow>
  );
};

export default BlogsRow;

export const SC = {
  BlogsRow: styled.div(
    ({ theme }) => css`
      position: relative;
      cursor: pointer;

      .blog {
        display: flex;
        justify-content: end;
        height: 100%;
        position: relative;
        border-radius: 18px;
        overflow: hidden;
        transition: 0.2s;

        :hover {
          .blog__content {
            background-color: #ffffff;
          }
        }

        .badge {
          border: 1px solid #000000;
          width: fit-content;
          border-radius: 14px;
          font: normal normal 13px/16px 'Area Normal Medium';
          letter-spacing: 0px;
          margin: 0.5rem 0;
          padding: 3px 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
          padding-top: 5px;

          &:hover {
            background: #f8e800 0% 0% no-repeat padding-box;
            border-color: #f8e800;
          }
        }

        &__image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid transparent;
          border-radius: 12px;

          img {
            height: 100%;
            object-fit: cover;
            min-height: 100%;
            min-width: 50%;
            aspect-ratio: 1 / 1;
          }
        }

        &__content {
          padding: 2rem;
          display: flex;
          flex-direction: column;
          z-index: 2;
          background-color: #f0eedf;
          max-width: 50%;
          gap: 2rem;
          transition: 0.3s ease-out;

          h3 {
            font: normal 950 18px/23px 'Area Normal Black';
          }

          p {
            padding-bottom: 4rem;
            flex: 1;
            font: normal normal 15px/16px 'Area Normal Medium';
          }

          .btn-arrow {
            font-size: 14px;
          }
        }
      }
    `
  ),
  BlogRow: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 3rem;
        padding-top: 120px;

        @media (max-width: ${sizes.mDevice}) {
          grid-template-columns: 1fr;
          padding-top: 8rem;
          padding-bottom: 3rem;
        }
      }
    `
  ),
};
