import styled, {css} from 'styled-components';
import {sizes} from '../../../../../styles/mediaQueries';

export const SC = {
  Services: styled.div<{ hasImage: boolean }>(
    ({ theme, hasImage }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      #services::before {
        display: block;
        content: " ";
        margin-top: -200px;
        height: 200px;
        visibility: hidden;
        pointer-events: none;
      }

      .yellow-container {
        background-color: #f8e800;
        border-radius: 20px;
        padding: 5rem 4rem;

        &.related {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 2rem;

          @media (max-width: ${sizes.sDeviceUp}) {
            display: flex;
            flex-direction: column;

          }

          p {
            margin: 0;
          }

          .yellow-container__list {
            @media (max-width: ${sizes.sDeviceUp}) {
              text-align: center;
              justify-content: center;
              align-items: center;
            }
          }


          .yellow-container__grid {
            display: block;
            margin: 0;
          }
        }


        :hover {
          background-color: #E8D900;
        }

        &__title {
          font: normal normal 950 30px/30px 'Area Extended ExtraBlack';
        }

        p {
          font: normal 17px / 24px 'Area Normal Medium';
          margin-top: 4rem;
        }

        &__grid {
          margin-top: 5rem;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 10rem;

          @media (max-width: ${sizes.mDevice}) {
            grid-template-columns: 1fr;
            grid-row-gap: 1rem;
          }

        }

        &__text {
          font: normal 15px/22px 'Area Normal Medium';
        }

        &__list {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          &__item {
            font: normal 14px/22px 'Area Normal Medium';
            border-radius: 20px;
            border: 1px solid black;
            padding: 0 1rem;
            width: fit-content;
            cursor: pointer;
            user-select: none;
            margin-bottom: 1rem;

            :hover {
              background-color: #fff;
              border-color: transparent;
            }

          }
        }


      \`  ),
    `
  ),
};
