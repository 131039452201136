import React from 'react';
import styled, {css} from 'styled-components';
import {sizes} from '../../../styles/mediaQueries';
import {useRouting} from '../../../routing/utils/useRouting';
import RouteLink from '../../app/components/RouteLink';
import {useSelector} from 'react-redux';
import {selectServiceList} from '../../service/reducer/selectors';
import uuid from 'react-uuid';

interface ComponentProps {}

const ServicesRow: React.FC<ComponentProps> = ({}) => {
  const { routes } = useRouting();
  const services = useSelector(selectServiceList);

  return (
    <SC.Services>
      {services &&
        services.content.map((service) => (
          <div className={'service'} key={service.id}>
            <h3>{service.title}</h3>
            <RouteLink className={'btn-arrow'} to={routes.service.url({ serviceSlug: service.url })}>
              Prozkoumat
              <div className={'arrow'} />
            </RouteLink>
            <ul>
              {service.children.map((child: { url: any; name: any }) => (
                <li key={`${child.name}-${service.id}`}>
                  <RouteLink to={routes.service.url({ serviceSlug: child.url })}>{child.name}</RouteLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
    </SC.Services>
  );
};

export default ServicesRow;

export const SC = {
  Services: styled.div(
    ({ theme }) => css`
      padding: 10rem 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: 1fr;
      gap: 20px;

      @media (max-width: ${sizes.mDevice}) {
        padding: 0;
        display: flex;
        flex-direction: column;
      }

      .service {
        background: #f8e800 0% 0% no-repeat padding-box;
        border-radius: 20px;
        padding: 5rem;
        position: relative;
        transition: background-color 0.3s ease-in-out;

        @media (max-width: ${sizes.mDevice}) {
          padding: 2rem 1.55rem;
        }

        &:hover {
          background: #e8d900;
        }

        h3 {
          font: normal normal 950 30px 'Area Extended Extrablack';
          @media (max-width: ${sizes.mDevice}) {
            font: normal normal 950 20px 'Area Extended Extrablack';
            text-align: center;
          }
        }

        .btn-arrow {
          position: absolute;
          font-size: 16px;
          margin-left: 19rem;
          justify-self: right;

          @media (max-width: ${sizes.mDevice}) {
            display: none;
          }
        }

        p {
          padding-top: 4rem;
          padding-bottom: 5rem;
          font: normal normal medium 20px/24px 'Area Normal Medium';
          text-align: justify;

          @media (max-width: ${sizes.mDevice}) {
            padding-top: 4rem;
            padding-bottom: 2rem;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          margin-top: 10rem;
          @media (max-width: ${sizes.mDevice}) {
            margin-top: 20px;
            text-align: center;
          }

          li {
            a {
              width: fit-content;
              display: inline-block;
              border: 1px solid #000000;
              border-radius: 14px;
              font: normal normal 14px/20px 'Area Normal Medium';
              letter-spacing: 0px;
              margin: 0.5rem 0;
              padding: 3px 1rem;
              cursor: pointer;
              transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

              &:hover {
                background: #fff 0% 0% no-repeat padding-box;
                border-color: #f8e800;
              }
            }
          }
        }
      }
    `
  ),
};
