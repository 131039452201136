import React from 'react';
import UspWrapper from '../../../homepage/components/uspWrapper';
import AboutUs from '../aboutUs';
import MainPromoComponent from './mainPromoComponent';
import ClientsBlock from '../../../homepage/components/clientsBlock';
import MainServicesPromoComponent from './mainServicesPromoComponent';
import GarantionBlock from '../../../homepage/components/garantionBlock';
import { getValueFromList } from '../../../page/helper';
import { PageBlockContentType } from '../../../app/models/enums';
import StudiesBlock from '../../../homepage/components/studiesBlock';
import { useSelector } from 'react-redux';
import { selectProjectList } from '../../../homepage/reducer/selectors';
import TextContainerImageComponent from './textContainerImageComponent';
import BlogsRow from '../../../homepage/components/blogsRow';
import { selectBlogList } from '../../../blog/reducer/selectors';
import ServicesComponent from './servicesComponent';
import StepsBlockComponent from './stepsBlockComponent';
import RelatedServicesComponent from './relatedServicesComponent';
import AboutUsBlock from '../../../homepage/components/aboutUsBlock';
import TextContainerImageBackground from './textContainerImageBackgroundComponent';
import PromoTextComponent from './promoTextComponent';
import GalleryComponent from './galleryComponent';
import ProjectClientLogoComponent from './projectClientLogoComponent';
import TextPlainComponent from './textPlainComponent';
import ProjectClientBlockComponent from './projectClientBlockComponent';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface ComponentProps {
  data: any;
}

const BlocksComponent: React.FC<ComponentProps> = ({ data, children }) => {
  const projects = useSelector(selectProjectList);
  const articles = useSelector(selectBlogList);

  if (!data) {
    return <></>;
  }

  return (
    <>
      {data.blocks.map((b: { type: string; title: string; data: any[]; content: string }) => {
        if (b.type == 'PROJECT_CLIENT_BLOCK') {
          return <ProjectClientBlockComponent data={b.data} />;
        }
        if (b.type == 'PLAIN_TEXT') {
          return <TextPlainComponent content={b.content} />;
        }

        if (b.type == 'PROJECT_CLIENT_LOGO') {
          return <ProjectClientLogoComponent data={b.data} />;
        }

        if (b.type == 'GALLERY') {
          return <GalleryComponent data={b.data} />;
        }

        if (b.type == 'PROMO_TEXT') {
          return <PromoTextComponent content={b.content} />;
        }

        if (b.type == 'TEXT_CONTAINER_IMAGE_RIGHT') {
          return (
            <TextContainerImageBackground
              title={b.title}
              content={b.content}
              image={getValueFromList(b.data, PageBlockContentType.TEXT_CONTAINER_IMAGE_BLOCK_IMAGE) as string}
              direction={'RIGHT'}
            />
          );
        }

        if (b.type == 'TEXT_CONTAINER_IMAGE_LEFT') {
          return (
            <TextContainerImageBackground
              title={b.title}
              content={b.content}
              image={getValueFromList(b.data, PageBlockContentType.TEXT_CONTAINER_IMAGE_BLOCK_IMAGE) as string}
              direction={'LEFT'}
            />
          );
        }

        if (b.type == 'PEOPLE_BLOCK') {
          return <AboutUsBlock title={b.title} data={b.data} />;
        }

        if (b.type == 'STEPS_BLOCK') {
          return <StepsBlockComponent title={b.title} data={b.data as any} />;
        }

        if (b.type == 'RELATED_SERVICES_BLOCK') {
          return <RelatedServicesComponent title={b.title} data={b.data as any} content={b.content} />;
        }

        if (b.type == 'SERVICE_LIST_BLOCK') {
          return <ServicesComponent title={b.title} data={data?.children} />;
        }

        if (b.type == 'BLOG_BLOCK') {
          return <BlogsRow list={articles?.content} />;
        }

        if (b.type == 'ROUNDED_IMAGE_TEXT_BOX') {
          const image = getValueFromList(b.data, PageBlockContentType.TEXT_CONTAINER_IMAGE_BLOCK_IMAGE);

          return (
            <TextContainerImageComponent
              title={b.title}
              content={b.content}
              cta={'Chci vstupní analýzu zdarma'}
              image={image || '/images/services/female-mobile.webp'}
              direction={'RIGHT'}
            />
          );
        }

        if (b.type == 'TEXT') {
          const image = getValueFromList(b.data, PageBlockContentType.TEXT_CONTAINER_IMAGE_BLOCK_IMAGE);

          return (
            <TextContainerImageComponent
              title={b.title}
              content={b.content}
              image={image || '/images/services/pexels-fauxels-3183165.webp'}
              direction={'LEFT'}
            />
          );
        }

        if (b.type == 'USP_BLOCK') {
          return <UspWrapper backGroundColor={'#f5f4ee'} title={b.title} data={b.data} />;
        }

        if (b.type == 'ABOUT_US_BLOCK') {
          return <AboutUs title={b.title} description={b.content} />;
        }

        if (b.type == 'MAIN_PROMO') {
          return <MainPromoComponent data={b.data} />;
        }

        if (b.type == 'CLIENTS_BLOCK') {
          return <ClientsBlock />;
        }

        if (b.type == 'MAIN_SERVICES_LIST_BLOCK') {
          return <MainServicesPromoComponent title={b.title} content={b.content} />;
        }

        if (b.type == 'MAIN_USP_BLOCK') {
          return (
            <GarantionBlock
              description={getValueFromList(b.data, PageBlockContentType.MAIN_USP_BLOCK_DESCRIPTION) as string}
            />
          );
        }

        if (b.type == 'STUDY_BLOCK') {
          return <StudiesBlock projects={projects} />;
        }

        if (b.type == 'APP_CONTENT_BLOCK') {
          return children;
        }

        return <></>;
      })}
    </>
  );
};

export default BlocksComponent;
