import React from 'react';
import styled, {css} from 'styled-components';
import RotaryImageBlock from '../../../homepage/components/rotaryImageBlock';
import {PageBlockContentType} from '../../../app/models/enums';
import {sizes} from '../../../../styles/mediaQueries';

interface ComponentProps {
  data: any[];
}

const ProjectClientBlockComponent: React.FC<ComponentProps> = ({ data }) => {
  const getValue = (type: PageBlockContentType, contents: any): string | null => {
    for (const r of contents) {
      if (r.type == type) {
        return r.value;
      }
    }
    return null;
  };

  return (
    <SC.ProjectClientBlock className={'mobile-hidden'}>
      <div className={'block-headline '}>
        <div>Co o naší spolupráci řekl klient</div>
      </div>
      <RotaryImageBlock
        image={getValue(PageBlockContentType.PROJECT_CLIENT_BLOCK_IMAGE, data) as string}
        text={getValue(PageBlockContentType.PROJECT_CLIENT_BLOCK_DESCRIPTION, data) as string}
        secondaryTitle={getValue(PageBlockContentType.PROJECT_CLIENT_BLOCK_NAME, data) as string}
        secondaryText={getValue(PageBlockContentType.PROJECT_CLIENT_BLOCK_POSITION, data) as string}
      />
    </SC.ProjectClientBlock>
  );
};

export default ProjectClientBlockComponent;

export const SC = {
  ProjectClientBlock: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .logo {
        width: 25rem;
        height: 25rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f7f6f0;
        border-radius: 50%;
        margin-top: 8rem;
        margin-bottom: 8rem;
        position: relative;

        img {
          max-width: 70%;
          margin: auto;
          //aspect-ratio: 1/1;
        }
      }
    `
  ),
};
