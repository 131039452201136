import React from 'react';
import styled, { css } from 'styled-components';
import { sizes } from '../../../styles/mediaQueries';
import { useRouting } from '../../../routing/utils/useRouting';
import RouteLink from '../../app/components/RouteLink';
import { useConfiguration } from '../../../configuration/useConfiguration';

interface ComponentProps {
  study: {
    id: string;
    title: string;
    url: string;
    image: string;
    category: {
      id: string;
      title: string;
      url: string;
    };
    shortDescription: string;
  };
}

const StudyBlock: React.FC<ComponentProps> = ({ study }) => {
  const { cdnUrl } = useConfiguration();
  const { routes, router } = useRouting();

  return (
    <SC.StudyBlock>
      <RouteLink to={routes.project.url({ projectSlug: study.url })}>
        <div className={'study'}>
          <div className={'image'}>
            <div className={'image__image'}>
              <img src={cdnUrl + study.image} alt={study.title} loading={'lazy'} />
            </div>
          </div>
          <div className={'content'}>
            <div className={'badge'} onClick={(e) => {
              e.preventDefault()
              router.push(routes.service.url({ serviceSlug: study.category.url }));
            }}>
              {study.category.title}
            </div>
            <h3 onClick={() => router.push(routes.project.url({ projectSlug: study.url }))}>{study.title}</h3>
            <p>{study.shortDescription}</p>
            <div className={'btn-arrow'}>
              Přečíst
              <div className={'arrow'} />
            </div>
          </div>
        </div>
      </RouteLink>
    </SC.StudyBlock>
  );
};

export default StudyBlock;

export const SC = {
  StudyBlock: styled.div(
    ({ theme }) => css`
      :hover {
        .rotary-holder__rotator {
          transform: rotate(360deg);
        }
      }

      .rotary-holder {
        width: 100%;
        height: 50%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        transition: transform 0.3s ease-in;
        z-index: 15;

        &__separator {
          height: 50%;
          width: 100%;
          overflow: hidden;
        }

        &__rotator {
          width: 100%;
          height: 200%;
          transform: rotate(180deg);
          display: flex;
          flex-direction: column;
          gap: 1px;
          transition: transform 0.15s ease-in;
        }

        &__hinge {
          background-color: #f8e800;
          width: 100%;
          height: 200%;
          padding-top: 3rem;

          &__image {
            width: 100%;
            height: 50%;
            position: relative;
            justify-content: center;
            display: flex;
            padding-left: 6rem;
            padding-right: 6rem;

            padding-top: 5rem;
            padding-bottom: 5rem;

            filter: brightness(100%) invert(1);

            img {
              height: fit-content !important;
              position: relative !important;
              width: fit-content !important;
              aspect-ratio: unset !important;
              border-radius: 0 !important;
              object-fit: unset !important;
            }
          }
        }
      }

      .study {
        padding: 0 3rem;

        .image {
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
          user-select: none;
          aspect-ratio: 1/1;
          width: 100%;
          text-align: center;
          background-color: #f0eedf;

          &__image {
            border: none;
            height: 100%;
          }
        }

        img {
          object-fit: cover;
          height: 100%;
          border-radius: 55%;
          box-sizing: border-box;
          border: 0;
          border: none;
        }

        .badge {
          display: inline-block;
          border: 1px solid #000000;
          border-radius: 14px;
          font: normal normal 14px/20px 'Area Normal Medium';
          letter-spacing: 0px;
          margin: 0.5rem 0;
          padding: 3px 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

          &:hover {
            background: #f8e800 0% 0% no-repeat padding-box;
            border-color: #f8e800;
          }
        }

        .content {
          padding-top: 3rem;

          @media (max-width: ${sizes.mDevice}) {
            padding-left: 0;
            padding-top: 3rem;
            margin-bottom: 2rem;
          }

          h3 {
            font: normal normal 950 22px/26px 'Area Normal Black';
            padding-top: 2rem;
            cursor: pointer;
          }

          p {
            padding-bottom: 4rem;
            font: normal normal medium 20px/24px 'Area Normal Medium';
            text-align: justify;

            @media (max-width: ${sizes.mDevice}) {
              padding-bottom: 40px;
            }
          }

          .btn-arrow {
            font-size: 14px;
            padding-bottom: 20px;
            padding-top: 20px;
          }
        }
      }
    `
  ),
};
