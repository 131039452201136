import React from 'react';
import RouteLink from '../../app/components/RouteLink';
import { useRouting } from '../../../routing/utils/useRouting';
import styled, { css } from 'styled-components';
import { sizes } from '../../../styles/mediaQueries';

export interface ComponentProps {
  title?: string | null;
  description?: string | null;
}

const AboutUs: React.FC<ComponentProps> = ({ title, description }) => {
  const { routes } = useRouting();

  return (
    <SC.AboutUsBlock>
      <div className={'about-us-container'}>
        <div className={'about-us about-us-image'}>
          <img loading="lazy" src={'/images/about/about-us.jpg'} alt={'about-us'} />
        </div>
        <div className={'about-us about-us-text'}>
          <div className={'about-us about-us-text__title'}>{title}</div>
          <div className={'about-us about-us-text__text'} dangerouslySetInnerHTML={{ __html: description as string }} />
          <RouteLink to={routes.aboutUs.url()} className={'btn-cta-primary'}>
            Více o nás
          </RouteLink>
        </div>
      </div>
    </SC.AboutUsBlock>
  );
};

export default AboutUs;

export const SC = {
  AboutUsBlock: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .about-us-container {
        background-color: #f0eedf;
        border-radius: 20px;
        display: flex;
        overflow: hidden;

        @media (max-width: ${sizes.mDevice}) {
          display: flex;
          flex-direction: column;
        }

        .about-us {
          max-width: 50%;

          @media (max-width: ${sizes.mDevice}) {
            max-width: 100%;
          }

          &-text {
            padding: 8rem 10rem 8rem 10rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: relative;

            @media (max-width: ${sizes.mDevice}) {
              padding: 5rem 3rem 5rem 3rem;
            }

            &__title {
              font: normal 950 30px 'Area Extended Extrablack';
              min-width: 100%;
              @media (max-width: ${sizes.mDevice}) {
                font: normal 950 20px 'Area Extended Extrablack';
              }
            }

            &__text {
              flex: 1;
              font: normal 15px/28px 'Area Normal Medium';
              min-width: 100%;

              a {
                cursor: pointer;
                font: normal 15px/28px 'Area Normal Extrablack';
              }
            }

            &__route {
              font: normal 950 13px 'Area Extended Extrablack';
            }
          }
        }
      }
    `
  ),
};
