import React from 'react';
import styled, { css } from 'styled-components';
import { sizes } from '../../../styles/mediaQueries';

interface ComponentProps {
  description?: string | null;
}

const GarantionBlock: React.FC<ComponentProps> = ({
  description = 'O projektu budete mít přehled, protože s vámi budeme vše průběžně konzultovat.',
}) => {
  return (
    <SC.ServicesGarantion>
      <div className={'text'}>{description}</div>
    </SC.ServicesGarantion>
  );
};

export default GarantionBlock;

export const SC = {
  ServicesGarantion: styled.div(
    ({ theme }) => css`
      display: flex;
      gap: 5rem;
      padding-bottom: 120px;
      justify-content: center;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
        padding-top: 40px;
      }

      .text {
        font: normal 950 25px/37px 'Area Extended Extrablack';
        width: 60vw;
        text-align: center;
        background-color: #f7f6f0;
        padding: 3rem;
        border-radius: 20px;

        @media (max-width: ${sizes.xsDevice}) {
          width: 100%;
        }
      }
    `
  ),
};
