import React from 'react';
import styled, {css} from 'styled-components';
import {sizes} from '../../../styles/mediaQueries';
import {useConfiguration} from '../../../configuration/useConfiguration';

interface ComponentProps {
  image: string;
  text: string;
  btnText?: string;
  btnFunction?: () => void;
  secondaryTitle?: string;
  secondaryText?: string;
}

const RotaryImageBlock: React.FC<ComponentProps> = ({
  image,
  text,
  btnText,
  btnFunction,
  secondaryTitle,
  secondaryText,
}) => {
  const { cdnUrl } = useConfiguration();

  return (
    <SC.RotaryImageBlock>
      <div className={'contact-us-block-rotary'}>
        <div className={'contact-us-block-rotary__image'}>
          <img src={cdnUrl + image} alt={text} loading={'lazy'} />
        </div>
        <div className={'contact-us-block-rotary__rotating-element'}>
          <div className={'contact-us-block-rotary__quarter-element'} />
        </div>
        {!!btnText && !!btnFunction && (
          <div className={'contact-us-block-rotary__button'} onClick={() => btnFunction()}>
            {btnText}
          </div>
        )}
        {(!!secondaryTitle || !!secondaryText) && (
          <div className={'contact-us-block-rotary__secondary-text'}>
            <div className={'contact-us-block-rotary__secondary-text__title'}>{secondaryTitle}</div>
            <div className={'contact-us-block-rotary__secondary-text__text'}>{secondaryText}</div>
          </div>
        )}
        <div className={'contact-us-block-rotary__text'}>{text}</div>
      </div>
    </SC.RotaryImageBlock>
  );
};

export default RotaryImageBlock;

export const SC = {
  RotaryImageBlock: styled.div(
    ({ theme }) => css`
      display: flex;
      justify-content: center;
      padding-left: 16vw;
      padding-top: 11rem;
      padding-bottom: 11rem;

      @media (max-width: ${sizes.sDeviceUp}) {
        display: none;
      }

      .contact-us-block-rotary {
        position: relative;
        width: fit-content;

        :hover {
          .contact-us-block-rotary__rotating-element {
            transform: rotate(-90deg);
          }

          .contact-us-block-rotary__button {
            opacity: 1;
          }
          .contact-us-block-rotary__secondary-text {
            opacity: 1;
          }
        }

        &__image {
          border-radius: 50%;
          overflow: hidden;
          height: 40rem;
          aspect-ratio: 1/1;

          img {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
          }
        }

        &__rotating-element {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transition: all 0.2s ease-in-out;
        }

        &__quarter-element {
          width: 50%;
          aspect-ratio: 1/1;
          background-color: #fff;
          right: 50%;
          top: 50%;
          position: absolute;
        }

        &__button {
          transition: background-color 0.1s ease-in-out;
          border: 1px solid black;
          background: none;
          border-radius: 4px;
          padding: 1.5rem 3rem;
          font: normal 950 15px 'Area Normal Black';
          width: fit-content;
          position: absolute;
          margin: 11rem 6rem;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
          user-select: none;
          :hover {
            background-color: #f6e600;
            border-color: transparent;
          }
        }

        &__secondary-text {
          transition: background-color 0.1s ease-in-out;
          padding: 1.5rem 3rem;
          font: normal 950 15px 'Area Normal Black';
          position: absolute;
          margin: 7.5rem 0;
          right: 0;
          bottom: 0;
          max-width: 50%;
          opacity: 0;
          &__title {
            font: normal 950 15px 'Area Extended Extrablack';
          }
          &__text {
            margin-top: 3rem;
            font: normal 13px/24px 'Area Normal Medium';
          }
        }

        &__text {
          font: normal 950 30px/36px 'Area Extended Extrablack';
          color: #000;
          text-align: right;
          position: absolute;
          top: 50%;
          right: 50%;
          width: 55rem;
          margin: 4.5rem 5rem;
        }
      }
    `
  ),
};
