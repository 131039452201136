import React from 'react';
import styled, {css} from 'styled-components';
import {sizes} from '../../../../styles/mediaQueries';
import {useConfiguration} from '../../../../configuration/useConfiguration';

interface ComponentProps {
  title: string;
  content: string;
  image?: string;
  cta?: string | null;
  direction?: string | null;
}

const TextContainerImageBackground: React.FC<ComponentProps> = ({ title, content, image, cta, direction = 'LEFT' }) => {
  const { cdnUrl } = useConfiguration();

  return (
    <SC.TextContainerImageBackground>
      <div className={'about-us-banner'}>
        {direction == 'LEFT' && (
          <div className={'about-us about-us-image'}>
            <img src={cdnUrl + image} alt={title} loading={'lazy'} />
          </div>
        )}
        <div className={'about-us about-us-text'}>
          <div className={'about-us about-us-text__title'}>{title}</div>
          <div className={'about-us about-us-text__text'}>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            {cta && (
              <div className={'btn-arrow about-us about-us-text__route'}>
                {cta}
                <div className={'arrow'} />
              </div>
            )}
          </div>
        </div>
        {direction == 'RIGHT' && (
          <div className={'about-us about-us-image'}>
            <img src={cdnUrl + image} alt={title} loading={'lazy'} />
          </div>
        )}
      </div>
    </SC.TextContainerImageBackground>
  );
};

export default TextContainerImageBackground;

export const SC = {
  TextContainerImageBackground: styled.div(
    ({ theme }) => css`
      .about-us-banner {
        background-color: #f0eedf;
        border-radius: 20px;
        display: flex;
        overflow: hidden;
        margin-bottom: 120px;

        @media (max-width: ${sizes.mDevice}) {
          display: none;
        }

        .about-us {
          max-width: 50%;

          &-text {
            padding: 8rem 10rem 8rem 10rem;
            display: flex;
            flex-direction: column;
            gap: 4rem;
            position: relative;

            &__title {
              min-width: 100%;
              font: normal 950 25px 'Area Extended Extrablack';
            }

            &__text {
              flex: 1;
              font: normal 15px/24px 'Area Normal Medium';
              min-width: 100%;

              p {
                margin-bottom: 2rem;
              }

              strong {
                font: 950 14px / 24px 'Area Extended ExtraBlack';
              }
            }

            &__route {
              font: normal 950 13px 'Area Extended Extrablack';
            }
          }

          &-image {
            min-width: 50%;

            img {
              max-width: 100%;
              box-sizing: initial;
              min-height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    `
  ),
};
