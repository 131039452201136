import React from 'react';
import styled, { css } from 'styled-components';
import { sizes } from '../../../styles/mediaQueries';

import clientsMock from '../../../../public/data/clients.json';

const content = clientsMock as {
  clients: Array<{
    id: string;
    title: string;
    image: string;
    study?: {
      id: string;
      title: string;
      url: string;
    } | null;
  }>;
};
const ClientsBlock: React.FC = () => {
  return (
    <SC.Clients>
      <div className={'block-headline'}>
        <div>Důvěřuje nám 100+ klientů</div>
      </div>
      <div className={'list'}>
        {content.clients.map((c) => (
          <div className={'row'}>
            <img loading="lazy" src={c.image} alt={c.title} />
          </div>
        ))}
      </div>
      <div className={'block-cta'}>
        <a href={'#contact'} className={'btn-cta-primary'}>
          Chci nezávaznou konzultaci
        </a>
      </div>
    </SC.Clients>
  );
};

export default ClientsBlock;

export const SC = {
  Clients: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 20px;
        padding-top: 120px;

        @media (max-width: ${sizes.mDevice}) {
          padding-top: 50px;
        }

        img {
          object-fit: contain;
          width: 100%;
          height: 5rem;
        }

        @media (max-width: ${sizes.mDevice}) {
          overflow: scroll;
          scrollbar-width: none;
        }

        .row {
          background: #f7f6f0;
          padding: 3rem 2rem;
          display: flex;
          justify-content: center;
          border-radius: 20px;
          position: relative;
          transition: background 0.3s ease-in-out;

          @media (max-width: ${sizes.mDevice}) {
            width: 225px;
          }

          &:hover {
            background: #f0eedf;
          }

          &:hover .study {
            background: #fff;
          }

          .study {
            position: absolute;
            top: 0;
            right: 0;
            color: #d9d7c7;
            border: 1px solid #f0eedf;
            border-radius: 14px;
            font: normal normal 12px/20px 'Area Normal Medium';
            letter-spacing: 0;
            line-height: 21px;
            padding: 0 5px;
            margin-top: 1rem;
            margin-right: 1rem;
            transition: background 0.3s ease-in-out;
          }
        }
      }
    `
  ),
};
