import {PageBlockContentType} from '../../app/models/enums';

export const getValueFromList = (list: any[], type: PageBlockContentType): string | null => {
  for (const r of list) {
    if (r.type == type) {
      return r.value;
    }
  }

  return null;
};

export const getRows = (contents: any) => {
  const map = new Map();

  for (const row of contents) {
    if (map.get(row.additionalInfo) == null) {
      map.set(row.additionalInfo, []);
    }
    const list = map.get(row.additionalInfo);

    map.set(row.additionalInfo, [...list, row]);
  }

  return new Map([...map.entries()].sort());
};
