import React from 'react';
import styled, {css} from 'styled-components';
import {useConfiguration} from '../../../../configuration/useConfiguration';
import {sizes} from '../../../../styles/mediaQueries';

interface ComponentProps {
  data: any[];
}

const ProjectClientLogoComponent: React.FC<ComponentProps> = ({ data }) => {
  const { cdnUrl } = useConfiguration();

  return (
    <SC.ProjectClientLogo>
      <div className={'logo'}>
        <img src={cdnUrl + data[0].value} alt={data[0].value} loading={'lazy'} />
      </div>
    </SC.ProjectClientLogo>
  );
};

export default ProjectClientLogoComponent;

export const SC = {
  ProjectClientLogo: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .logo {
        width: 25rem;
        height: 25rem;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f7f6f0;
        border-radius: 50%;
        position: relative;

        img {
          max-width: 70%;
          margin: auto;
          //aspect-ratio: 1/1;
        }
      }
    `
  ),
};
