import React from 'react';
import styled, { css } from 'styled-components';
import { sizes } from '../../../styles/mediaQueries';
import { getRows, getValueFromList } from '../../page/helper';
import UspRow from './uspRow';
import { PageBlockContentType } from '../../app/models/enums';

interface ComponentProps {
  backGroundColor?: string;
  numberOfColumns?: number;
  textAling?: string;
  title?: string;
  data: any;
}

const UspWrapper: React.FC<ComponentProps> = ({ numberOfColumns = 3, textAling = 'justify', title, data }) => {
  return (
    <SC.USP numberOfColumns={numberOfColumns} textAling={textAling}>
      <div className={'block-headline'}>
        <div>{title}</div>
      </div>
      <div className={'technology'}>
        <div className={'container'}>
          {[...getRows(data)].map(([key, value]) => {
            return (
              <UspRow
                title={getValueFromList(value, PageBlockContentType.USP_BLOCK_ROW_TITLE) as string}
                description={getValueFromList(value, PageBlockContentType.USP_BLOCK_ROW_DESCRIPTION) as string}
              />
            );
          })}
        </div>
      </div>
    </SC.USP>
  );
};

export default UspWrapper;

export const SC = {
  USP: styled.div<{ numberOfColumns: number; textAling: string }>(
    ({ theme, numberOfColumns, textAling }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .block {
        &-headline {
          padding: 0;

          @media (max-width: ${sizes.mDevice}) {
            padding-bottom: 40px;
          }
        }
      }

      .bubble {
        border-radius: 20px;
        background-color: #f0eedf;
        display: flex;
        padding: 3rem;
        transition: 0.3s ease-in-out;

        &:hover .plus {
          transform: rotate(90deg);
        }

        .plus {
          display: block;
          height: 40px;
          width: 40px;
          //background: red;
          transition: transform 0.3s ease-in;
          position: relative;
          margin-bottom: 2rem;

          @media (max-width: ${sizes.mDevice}) {
            margin-top: 0;
            margin-bottom: 3rem;
          }

          .line {
            position: absolute;
            display: block;
            width: 1px;
            height: 25px;
            //position: absolute;
            background: #000000 0 0 no-repeat padding-box;
            opacity: 1;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: ${sizes.mDevice}) {
              height: 31px;
            }

            &-vertical {
              transform: rotate(90deg);
              top: 20%;
              @media (max-width: ${sizes.mDevice}) {
                top: 12%;
              }

              //transform: translateY(-50%);
            }
          }
        }

        :hover {
          background-color: #d9d7c7;
        }

        .content-bubble {
          padding-right: 2.5rem;
          display: flex;

          &__bubble {
            background-color: #f7f6f0;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
          }
        }

        .content-text {
          padding-left: 3rem;
          .title {
            font: normal normal 950 20px 'Area Normal Black';
            padding-bottom: 20px;
          }

          p {
            font: normal normal medium 20px/24px 'Area Normal Medium';
          }
        }
      }

      .technology {
        padding-top: 120px;

        @media (max-width: ${sizes.mDevice}) {
          padding: 0;
        }

        .container {
          display: grid;
          grid-auto-rows: 1fr;
          gap: 3rem;
          grid-template-columns: repeat(${numberOfColumns}, 1fr);
          @media (max-width: ${sizes.mDevice}) {
            grid-template-columns: 1fr;
          }
        }

        .row {
          position: relative;
          display: grid;
          grid-auto-rows: 1fr;
          grid-template-columns: 1fr 6fr;
          margin-bottom: 4rem;

          @media (max-width: ${sizes.mDevice}) {
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;
          }

          &:last-child {
            @media (max-width: ${sizes.mDevice}) {
              margin: 0;
            }
          }

          &:hover .plus {
            transform: rotate(90deg);
          }

          .plus {
            display: block;
            height: 40px;
            width: 40px;
            //background: red;
            transition: transform 0.3s ease-in;
            position: relative;
            margin-bottom: 2rem;

            @media (max-width: ${sizes.mDevice}) {
              margin-top: 0;
              margin-bottom: 3rem;
            }

            .line {
              position: absolute;
              display: block;
              width: 1px;
              height: 25px;
              //position: absolute;
              background: #000000 0 0 no-repeat padding-box;
              opacity: 1;
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
              top: 50%;
              transform: translateY(-50%);

              @media (max-width: ${sizes.mDevice}) {
                height: 31px;
              }

              &-vertical {
                transform: rotate(90deg);
                top: 20%;
                @media (max-width: ${sizes.mDevice}) {
                  top: 12%;
                }

                //transform: translateY(-50%);
              }
            }
          }

          .content {
            margin-top: 5rem;
            text-align: ${textAling};
            padding: 0 3rem;

            @media (max-width: ${sizes.mDevice}) {
              margin-top: 0;
              width: 100%;
              padding: 0 0;
            }

            h3 {
              font: normal normal 950 20px 'Area Normal Black';
            }

            p {
              font: normal normal medium 20px/24px 'Area Normal Medium';
            }
          }
        }
      }
    `
  ),
};
