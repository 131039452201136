import React, { useState } from 'react';
import { SC } from './styles/stepsBlockComponent';
import { ServicesRowChildren } from '../../../service/models';
import { getRows, getValueFromList } from '../../../page/helper';
import { PageBlockContentType } from '../../../app/models/enums';

interface ComponentProps {
  title: string;
  data: ServicesRowChildren[];
  image?: string;
  cta?: string | null;
  direction?: string | null;
}

const StepsBlockComponent: React.FC<ComponentProps> = ({ title, data, image }) => {
  const [activeStep, setActiveStep] = useState<number>(1);

  return (
    <SC.StepsBlockComponent hasImage={!!image}>
      <div className={'block-headline'}>
        <div>{title}</div>
      </div>
      <div className={'steps-container'}>
        {[...getRows(data)].map(([key, value]) => {
          const active = activeStep == key ? 'active' : '';

          return (
            <div key={`steps-${key}`} onClick={() => setActiveStep(key)} className={`steps-container__block ${active}`}>
              <div className={'steps-container__block__number'}>{`0${key}`}</div>
              <div className={`steps-container__block__title ${active}`}>
                {getValueFromList(value, PageBlockContentType.STEPS_BLOCK_ROW_TITLE) as string}
              </div>
              <div className={`steps-container__block__text ${active}`}>
                {getValueFromList(value, PageBlockContentType.STEPS_BLOCK_ROW_DESCRIPTION) as string}
              </div>
              <div className={`steps-container__block__cross ${active}`}>
                <div className={'steps-container__block__cross__top'} />
                <div className={'steps-container__block__cross__left'} />
              </div>
            </div>
          );
        })}
      </div>
      <div className={'block-cta'}>
        <a href={'#contact'} className={'btn-cta-secondary'}>
          Chci nezávaznou konzultaci
        </a>
      </div>
    </SC.StepsBlockComponent>
  );
};

export default StepsBlockComponent;
