import {State} from '../../../types';

export const selectBlogList = (state: State) => {
  return state.blog.blog.list;
};

export const selectBlogCategoryList = (state: State) => {
  return state.blog.blog.categories;
};

export const selectBlogDetail = (state: State) => {
  return state.blog.blog.detail.data;
};
