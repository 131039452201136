import React from 'react';
import {SC} from './styles/servicesComponent';
import RouteLink from '../../../app/components/RouteLink';
import {useRouting} from '../../../../routing/utils/useRouting';
import {ServicesRowChildren} from '../../../service/models';

interface ComponentProps {
  title: string;
  data: ServicesRowChildren[];
  image?: string;
  cta?: string | null;
  direction?: string | null;
}

const Services: React.FC<ComponentProps> = ({ title, data, image }) => {
  const { routes } = useRouting();

  return (
    <SC.Services hasImage={!!image}>
      <div id={'services'} />
      <div className={'container yellow-container'}>
        <div className={'yellow-container__title'}>{title}</div>
        <div className={'yellow-container__grid'}>
          {data.map((ch) => (
            <RouteLink className={'yellow-container__list__item'} to={routes.service.url({ serviceSlug: ch.url })}>
              {ch.name}
            </RouteLink>
          ))}
        </div>
      </div>
    </SC.Services>
  );
};

export default Services;
