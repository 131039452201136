import React from 'react';
import styled, {css} from 'styled-components';
import {useConfiguration} from '../../../../configuration/useConfiguration';
import {sizes} from '../../../../styles/mediaQueries';

interface ComponentProps {
  data: any[];
}

const GalleryComponent: React.FC<ComponentProps> = ({ data }) => {
  const { cdnUrl } = useConfiguration();

  return (
    <SC.Gallery>
      <div className={'gallery'}>
        {data.map((r) => (
          <div className={'gallery-image'} key={r.id}>
            <img src={cdnUrl + r.value} alt={r.value} loading={'lazy'} />
          </div>
        ))}
      </div>
    </SC.Gallery>
  );
};

export default GalleryComponent;

export const SC = {
  Gallery: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .gallery {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2rem;
        row-gap: 2rem;
        width: 100%;
      }

      .gallery-image {
        position: relative;
        overflow: hidden;
        border-radius: 20px;

        &.large {
          grid-column: span 2;
          grid-row: span 2;
        }

        img {
          //width: 100%;
          min-height: 100%;
        }
      }
    `
  ),
};
