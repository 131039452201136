import styled, {css} from 'styled-components';
import {sizes} from '../../../../../styles/mediaQueries';

export const SC = {
  StepsBlockComponent: styled.div<{ hasImage: boolean }>(
    ({ theme, hasImage }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .block {
        &-headline {
          padding-bottom: 70px;
          @media (max-width: ${sizes.mDevice}) {
            padding-bottom: 50px;
          }
        }
      }

      .steps-container {
        border-radius: 20px;
        display: flex;
        position: relative;
        overflow: hidden;

        @media (max-width: ${sizes.mDevice}) {
          flex-direction: column;
        }

        &__block {
          position: relative;
          cursor: pointer;
          margin-left: -3rem;
          height: 30rem;
          display: flex;
          flex-direction: column;
          gap: 4rem;
          padding: 5rem 4rem;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          background: transparent linear-gradient(90deg, #e8d900 0%, #f8e800 100%) 0% 0% no-repeat padding-box;
          transition: width 0.3s ease-in-out;
          width: 20%;

          @media (max-width: ${sizes.mDevice}) {
            height: 15rem;
            max-height: 15rem;
            overflow: hidden;
            width: 100% !important;
            border-bottom-left-radius: unset;
            border-top-right-radius: 20px;
            margin-left: 0;
            margin-top: -3rem;
            flex-direction: row;
            flex-wrap: wrap;
            transition: height 0.3s ease-in-out;
            background: transparent linear-gradient(180deg, #e8d900 0%, #f8e800 100%) 0% 0% no-repeat padding-box;
          }

          :hover {
            .steps-container__block__cross {
              transform: rotate(90deg);
            }
          }

          :first-child {
            margin-left: 0;
            @media (max-width: ${sizes.mDevice}) {
              margin-top: 0;
            }
          }

          &.active {
            width: 50%;
            @media (max-width: ${sizes.mDevice}) {
              height: fit-content;
              max-height: fit-content;
            }
          }

          &__title {
            font: normal normal 950 15px/15px 'Area Extended ExtraBlack';
            flex: 1;
            transition: font 0.3s ease-in-out;

            &.active {
              font: normal normal 950 30px/30px 'Area Extended ExtraBlack';

              @media (max-width: ${sizes.mDevice}) {
                font: normal normal 950 20px/30px 'Area Extended ExtraBlack';
              }
            }
          }

          &__number {
            font: normal normal 950 47px 'Area Extended ExtraBlack';
            color: white;

            @media (max-width: ${sizes.mDevice}) {
              font-size: 30px;
            }
          }

          &__text {
            font: normal 15px/22px 'Area Normal Medium';
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            min-width: 55rem;
            @media (max-width: ${sizes.mDevice}) {
              min-width: 100%;
              min-height: fit-content;
            }

            &.active {
              opacity: 1;
            }
          }

          &__cross {
            margin: 5rem 4rem;
            position: absolute;
            height: 4rem;
            width: 4rem;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            display: block;
            bottom: 0;
            left: 0;
            opacity: 1;

            @media (max-width: ${sizes.mDevice}) {
              display: none;
            }

            &.active {
              opacity: 0;
            }

            &__left {
              border-left: 1px solid black;
              position: absolute;
              top: 0;
              left: 50%;
              width: 100%;
              height: 100%;
            }

            &__top {
              border-top: 1px solid black;
              position: absolute;
              top: 50%;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    `
  ),
};
