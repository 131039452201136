import React from 'react';
import styled, {css} from 'styled-components';
import {sizes} from '../../../../styles/mediaQueries';

interface ComponentProps {
  content: string;
}

const PromoTextComponent: React.FC<ComponentProps> = ({ content }) => {
  return (
    <SC.PromoText>
      <div className={'text'} dangerouslySetInnerHTML={{ __html: content }} />
    </SC.PromoText>
  );
};

export default PromoTextComponent;

export const SC = {
  PromoText: styled.div(
    ({ theme }) => css`
      padding-bottom: 120px;

      @media (max-width: ${sizes.mDevice}) {
        padding-bottom: 40px;
      }

      .text {
        font: normal 950 33px/46px 'Area Extended ExtraBlack';
        margin-bottom: 3rem;
      }
    `
  ),
};
